import React from "react";

const SidebarWidget = () => {
  return (
    <div className="sidebar-widget" id="sidebar-widget-js">
      <iframe
        title="score-bing"
        style={{ height: "564px", width: "315px" }}
        src="https://www.scorebing.com/widgets/table?lid=35,36,117,607,252&c=2F8162&f=FFF"
        scrolling="auto"
        border="0"
        frameborder="0"
      ></iframe>
    </div>
  );
};

export default SidebarWidget;
